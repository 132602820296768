$(function () {

	/* SLIDER RECRUTEMENT A VIRER QUAND PLUS D'ACTU */
	var $slider = $('.homeSlider.desktop');

	if ($slider.length) {

		var $sliderElements = $slider.find('.article');
		var sliderElementLastIndex = $sliderElements.length - 1;
		var timer;
		var actualIndex = 0;
		var step = function () {

			clearTimeout(timer);

			timer = setTimeout(function () {

				if (actualIndex < sliderElementLastIndex) {

					actualIndex++;
				} else {

					actualIndex = 0;
				}

				$sliderElements.removeClass('show');
				$sliderElements.eq(actualIndex).addClass('show');

				step();
			},4000);
		};

		clearTimeout(timer);

		step();
	}
	/******************************************************/

	var menu = $("#mainMenu");
	var item = $('.menu_item', menu);
	var shad = $("#modalShade");

	function onMenuItemHoverOn(evt) {

		var submenu = $(".submenu", this);

		evt.preventDefault();

		if (submenu.length) {

			shad.addClass("visible");
			submenu.addClass("open");
		}
	}

	function onMenuItemHoverOff(evt) {

		var submenu = $(".submenu", this);

		evt.preventDefault();

		if (submenu.length) {

			shad.removeClass("visible");
			submenu.removeClass("open");
		}
	}

	item.hover(onMenuItemHoverOn, onMenuItemHoverOff);

        $('a', menu).on('click touchend', function(e) {
            var el = $(this);
            var link = el.attr('href');
            window.location = link;
        });

});
