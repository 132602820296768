$(function () {

	var menu = $("#businessMenu");
	var view = $("#businessView");

	menu.on("click", "[data-unit]", function (evt) {

		var menu_target = $(this);
		var menu_itm = $("[data-unit]", menu).not(menu_target);
		var view_itm = $("[data-unit]", view);
		var view_target = view_itm.filter("[data-unit=" + menu_target.data("unit") + "]");

		evt.preventDefault();

		if (menu_target.toggleClass("current").hasClass("current")) {

			// If class "current" has been added
			// Show pannel if needed
			// Remove class "current" on other buttons
			view.addClass("open");
			view_target.addClass("current");

			view_itm.not(view_target).removeClass("current");
			menu_itm.removeClass("current");
		} else {

			// If class "current" has been removed
			// Hide pannel
			view_itm.removeClass("current");
			view.removeClass("open");
		}
	});
});
