(function(i, s, o, g, r, a, m) {

    i["GoogleAnalyticsObject"] = r;
    i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = Date.now();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m.nextElementSibling);
}(window, document, "script", "//www.google-analytics.com/analytics.js", "ga"));

ga("create", "UA-16718438-1", "auto");
ga("send", "pageview");
