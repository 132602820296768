$(function () {

    "use strict";

    // Cache required elements on startup
    // so we don't call jQuery each time they are needed
    var viewport = $("#video-viewport");
    var video = $("video", viewport);
    var vidsrc = $("#mp4Source", video);
    var videobg = viewport.parent();

    function shuffle(array) {

        var i = array.length - 1;
        var j = 0;
        var t = null;

        while (i > 0) {

            j = Math.floor(Math.random() * (i + 1));
            t = array[i];

            array[i] = array[j];
            array[j] = t;

            i -= 1;
        }

        return array;
    }

    function randomizeVideos() {

        var videos = [1, 2, 3];
        var vidUri = vidsrc.attr("src");
        var i = 0;
        var first = parseFloat(vidUri.match(/video_(\d)/)[1]);

        videos.splice(first - 1, 1);

        videos = shuffle(videos);

        videos.unshift(first);
        video.get(0).load();

        video.get(0).addEventListener("ended", function () {

            viewport.removeClass("playing");

            setTimeout(function () {

                i = (i + 1) % 3;
                vidUri = vidUri.replace(/video_\d/, "video_" + videos[i]);

                vidsrc.attr("src", vidUri);
                video.get(0).load();
            }, 350);
        });

        video.get(0).addEventListener("canplay", function () {

            video.get(0).play();
            viewport.addClass("playing");

            setTimeout(function () {

                var color = (videos[i] === 2 || videos[(i + 1) % 3] === 2)
                    ? "white"
                    : "black";

                videobg.attr("data-bgcolor", color);
            }, 350);
        });
    }

    $(window).on({load: randomizeVideos});
});
